import { useState, useEffect } from 'react'
import { getStrapiHero } from '../data/getStrapiHero'
import { Link } from 'react-scroll'
import Navbar from './Navbar'

const Home = () => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'heroes?populate=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiHero(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  return (
    <div>
      <Navbar strapiContent={strapiContent} />
      <header id={`index + ${1}`}>
        {strapiContent?.map(({ id, heroTitle, heroButton, heroBackground }) => (
          <div
            key={id}
            id='home'
            className='w-full bg-cover bg-center'
            style={{ backgroundImage: `url(${heroBackground})`, height: '32rem' }}
          >
            <div className='flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-40 dark:bg-opacity-60'>
              <div className='flex items-center justify-center flex-col text-center'>
                <h1 className='text-white text-2xl font-semibold uppercase md:text-3xl pb-4'>
                  {heroTitle}
                </h1>
                <div>
                  <Link
                    to='contact'
                    spy
                    smooth
                    className='animate-bounce duration-0 delay-0 mx-1 mt-4 px-4 py-2 bg-white text-dark text-sm text-gray-500 dark:text-gray-400 font-light rounded-md dark:bg-slate-600 cursor-pointer hover:bg-gray-200 focus:outline-none focus:bg-sky-500'
                  >
                    {heroButton}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </header>
    </div>
  )
}

export default Home
