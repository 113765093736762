/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { getStrapiAbout } from '../data/getStrapiAbout'

const About = () => {
  const [strapiAboutContent, setStrapiAboutContent] = useState([])
  const collectionData = 'abouts?populate[about_image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiAbout(collectionData)
      setStrapiAboutContent(data)
    }
    fetchData()
  }, [])

  return (
    <div className='bg-neutral-100 dark:bg-slate-800 pt-10 '>
      {strapiAboutContent?.map(({ id, about_title, about_subtitle, about_content, background }) => (
        <div className="w-full" id="about" key={id}>
          <div className="mx-auto container">
            <div className="grid justify-items-center w-full text-center sm:mt-10 ">
              <p className='pt-5 text-center text-dark text-2xl font-medium uppercase md:text-3xl text-slate-700/70 dark:text-slate-300'>
                {about_title}
              </p>
              <p className="sm:md:text-sm text-xs sm:md:w-[65%] px-5 text-dark dark:text-sky-400 py-2">
                {about_subtitle}
              </p>
              <p className="sm:md:text-sm text-xs sm:md:px-10 px-5 text-dark dark:text-sky-400 py-2">
                {about_content}
              </p>
            </div>
          </div>
          {background && ( <img src={background} className="w-[80%] mx-auto my-0 rounded-md" alt="background image" /> )}
        </div>
      ))}
    </div>
  )
}

export default About
/* eslint-enable */
