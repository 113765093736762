import { useState } from 'react'
import { Link } from 'react-scroll'
import ButtonTheme from './ButtonTheme'

const Navbar = ({ strapiContent }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [theme, setTheme] = useState(true)

  const LinkForComputer = ({ text, path, id }) => (
    <Link to={path} key={id} spy smooth className='py-4 px-6 hover:border-b-2 hover:border-b-orange-400 text-gray-500 dark:text-gray-400 font-semibold hover:text-orange-900 dark:hover:text-orange-400 transition duration-300'>{text}</Link>
  )

  const LinkForMobile = ({ text, path, id }) => (
    <Link to={path} key={id} spy smooth className='block text-sm px-2 py-4 hover:bg-orange-700 hover:text-white transition duration-300'>{text}</Link>
  )

  return (
    <nav className='navbar-container bg-white dark:bg-slate-900/50 shadow-lg'>
      {strapiContent?.map(({ id, headerLogo, sectionsOnPage }) =>
        <div className='max-w-6xl mx-auto' id={id} key={id}>
          <div className='flex justify-between'>
            <div>
              <Link key={id} to='home' className='flex items-center py-4 px-2'>
                <img src={headerLogo} className='header-logo' alt='Carpet Logo' />
              </Link>
            </div>
            <div className='hidden md:flex items-center space-x-1'>
              {sectionsOnPage?.map(item => (
                <LinkForComputer id={item.id} key={item.id} text={item.section} path={item.idSection} />
              ))}
            </div>
            <div className='hidden md:flex items-center space-x-3 '>
              <ButtonTheme theme={theme} setTheme={setTheme} />
            </div>
            <div onClick={() => setIsOpen(!isOpen)} className='md:hidden flex items-center'>
              <button className='outline-none mobile-menu-button'>
                <svg
                  className=' w-6 h-6 text-gray-500 hover:text-orange-500 dark:text-white'
                  x-show='!showMenu'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path d='M4 6h16M4 12h16M4 18h16'> </path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <div className='mobile-menu'>
          <ul>
            {strapiContent?.map(({ sectionsOnPage, id }) => (
              <div key={id}>
                {sectionsOnPage.map(item => (
                  <LinkForMobile id={item.id} path={item.section} key={item.section} />
                ))}
              </div>
            ))}
          </ul>
        </div>
      )}
    </nav>
  )
}

export default Navbar
