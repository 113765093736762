/* eslint-disable */
import React, { useState, useRef } from "react";
import emailjs from '@emailjs/browser';

const FormContactnew = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log("Sending email...");

    emailjs.sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current, {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        })
      .then(
        (result) => {
          console.log("Email sent successfully", result);
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000);
        },
        (error) => {
          console.error("Error sending email", error);
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000);
        }
      );

    e.target.reset();
  };

  return (
    <div id='contact' className="bg-neutral-100 dark:bg-slate-800">
      <div className="flex justify-center">
        <h2 className='pt-5 text-center text-dark text-2xl font-medium uppercase md:text-3xl text-slate-700/70 dark:text-slate-300'>Contact Us</h2>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <div className='grid gap-6 lg:grid-cols-2 p-5 w-2/3 m-auto'>
          <div>
            <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>First name</label>
            <input type='text' name="user_name" className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder='John' required />
          </div>
          <div>
            <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>Last name</label>
            <input type='text' name='surname' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder='Doe' required />
          </div>
          <div>
            <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>Email</label>
            <input type='text' name='email' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder='email@example.com' required />
          </div>
          <div>
            <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>Phone number</label>
            <input type='tel' name='phone' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' placeholder='1234536782' required />
          </div>
          <div className='col-span-2'>
            <textarea name='message' className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' cols='30' rows='10'></textarea>
          </div>
          <div className='col-span-2 grid justify-items-center'>
            <input type="submit" value="Send" disabled={isSubmitting} className="text-white bg-[#24292F] hover:bg-[#24292F]/90 focus:ring-4 focus:outline-none focus:ring-[#24292F]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-500 dark:hover:bg-[#050708]/30 mr-2 mb-2" />
              {stateMessage && <p>{stateMessage}</p>}
          </div>
        </div>
      </form>
    </div>
  )
};

export default FormContactnew
/* eslint-enabled */
