/* eslint-disable */
export const getStrapiServices = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        servicesTitle: attributes.services_title,
        servicesSubtitle: attributes.service_subtitle,
        galleryTitle: attributes.gallery_title,
        servicesCards: attributes.services_cards?.data.map(({attributes, id }) => ({
          id: id,
          cardTitle: attributes.card_title,
          cardImage: process.env.REACT_APP_STRAPI_URL + attributes.card_image.data.attributes.url
        })),
        ourProjects: attributes.our_projects?.data.map(({attributes}) => ({
          original: process.env.REACT_APP_STRAPI_URL + attributes.url,
          thumbnail: process.env.REACT_APP_STRAPI_URL + attributes.url
        }))

      }
    }
  )

  return values
}
/* eslint-enable */
