import React, { useState, useEffect } from 'react'
import { getStrapiServices } from '../data/getStrapiServices'
import ImageGallery from 'react-image-gallery'

const NewGallery = () => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'services-groups?populate[our_projects][populate]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiServices(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  return (
    <div className='bg-neutral-100 dark:bg-slate-800 px-4 flex justify-center items-center'>
      {strapiContent?.map(({ ourProjects, id, galleryTitle }) => (
        <div key={id} id='gallery' className='w-full mb-[2rem]'>
          <div className='flex justify-center my-6'>
            <h2 className='pt-5 text-center text-dark dark:text-white text-2xl font-medium uppercase md:text-3xl'>{galleryTitle}</h2>
          </div>
          <div key={id} className='w-[80%] md:w-[85%] mx-auto'>
            <ImageGallery items={ourProjects} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default NewGallery
