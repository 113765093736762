/* eslint-disable */
export const getStrapiAbout = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      const imageUrl = attributes?.about_image?.data?.attributes?.url;
      return {
        id: id,
        about_title: attributes?.about_title,
        about_subtitle: attributes?.about_subtitle,
        about_content: attributes?.about_content,
        background: imageUrl ? `${process.env.REACT_APP_STRAPI_URL}${imageUrl}` : undefined,
      }
    }
  )

  return values
}
/* eslint-enable */
