/* eslint-disable */
export const getStrapiHero = async (collectionId) => {
  const collection = await (await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_TOKEN_STRAPI}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        headerName: attributes.nav_title,
        headerLogo: process.env.REACT_APP_STRAPI_URL + attributes.nav_logo.data.attributes.url,
        heroBackground: process.env.REACT_APP_STRAPI_URL + attributes.hero_background.data.attributes.url,
        heroTitle: attributes.hero_title,
        heroButton: attributes.hero_button,
        sectionsOnPage: attributes.nav_items.sections_on_page.map(({section, id_section}) => ({
          id: Math.floor(Math.random() * 100),
          section: section,
          idSection: id_section
        }))
      }
    }
  )

  return values
}
/* eslint-enable */
